// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import 'cocoon';
require("@rails/ujs").start()
//= require("turbolinks").start()
//= require select2
//= require jquery
//= require_tree .
//= require cleave.js
//= require cleave
require("@rails/activestorage").start()
require("channels")
require("packs/customer")
require("packs/price_list")
require("packs/product")
require("packs/order")
require("packs/bill")
require("packs/user")
require("packs/numeration")



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(document).ready(function() {

  $("#btn_search_products").click(function(e){
    e.preventDefault();
    $.get($("#search_products").attr('action'), $("#search_products").serialize(), null, 'script')
    .complete(function(data){});
  })

  $('.text-area-full').hide();
  $('.text-area').hover(
    function () {
      $(this).find(".text-area-truncate").hide();
      $(this).find(".text-area-full").show();
    },

    function () {
      $(this).find(".text-area-truncate").show();
      $(this).find(".text-area-full").hide();
    }
  );

  $(".number").on({
    "focus": function (event) {
      $(event.target).select();
    },
    "keyup": function (event) {
      $(event.target).val(function (index, value ) {
        return value.replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1.$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
      });
    }
  });

  $("input, textarea").on("keyup",function (){
    this.value = this.value.toUpperCase();
  });


  $('.single-select').select2();

  $('.multiple-select').select2();


  $('.currency-input-mask').each(function(i, el) {
    new Cleave(el, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    });
  });

  //multiselect start

  // $('#my_multi_select1').multiSelect();
  // $('#my_multi_select2').multiSelect({
  //   selectableOptgroup: true
  // });
  //
  // $('#my_multi_select3').multiSelect({
  //   selectableHeader: "<input type='text' class='form-control search-input' autocomplete='off' placeholder='search...'>",
  //   selectionHeader: "<input type='text' class='form-control search-input' autocomplete='off' placeholder='search...'>",
  //   afterInit: function (ms) {
  //     var that = this,
  //     $selectableSearch = that.$selectableUl.prev(),
  //     $selectionSearch = that.$selectionUl.prev(),
  //     selectableSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selectable:not(.ms-selected)',
  //     selectionSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selection.ms-selected';
  //
  //     that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
  //     .on('keydown', function (e) {
  //       if (e.which === 40) {
  //         that.$selectableUl.focus();
  //         return false;
  //       }
  //     });
  //
  //     that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
  //     .on('keydown', function (e) {
  //       if (e.which == 40) {
  //         that.$selectionUl.focus();
  //         return false;
  //       }
  //     });
  //   },
  //   afterSelect: function () {
  //     this.qs1.cache();
  //     this.qs2.cache();
  //   },
  //   afterDeselect: function () {
  //     this.qs1.cache();
  //     this.qs2.cache();
  //   }
  // });
  //
  // $('.custom-header').multiSelect({
  //   selectableHeader: "<div class='custom-header'>Selectable items</div>",
  //   selectionHeader: "<div class='custom-header'>Selection items</div>",
  //   selectableFooter: "<div class='custom-header'>Selectable footer</div>",
  //   selectionFooter: "<div class='custom-header'>Selection footer</div>"
  // });

});
