$(document).ready(function () {

  $("#div_allied_id").hide();
  $("#div_seat_id").hide();
  if($("#user_type_id").val() != "3"){
    $("#div_seat_id").show();
  }else{
    if($("#user_type").val() == "general"){
      $("#div_allied_id").show();
    }else{
      $("#div_seat_id").show();
    }
  }
  checkAll();
  //document.getElementById('adviser_code_users_form').disabled = true;
  $("#checkAll").change(function () {
    $("input:checkbox").prop("checked", $(this).prop("checked"));
  });

  $("#user_user_type_id").change(function () {
    if ($(this).val() === 'Asesor') document.getElementById('adviser_code_users_form').disabled = false;
    else document.getElementById('adviser_code_users_form').disabled = true;
  })

  $(".checkbox-permission").change(function () {
    var checkbox_classes = $(this).attr('class').split(' ')[1];
    var checkbox_class = ""
    for (var i = 1, l = checkbox_classes.length; i < l; i++) {
      checkbox_class = checkbox_class + " " + checkbox_classes[i]
    }
    // var checkbox_classes = $(this).attr('class').split(' ');
    if ($(this).is(":checked") == false) {
      $("#checkAll").prop("checked", false);
      $("#" + checkbox_classes).prop("checked", false);
    };
    var checkboxsg = $("." + checkbox_classes);
    var allg = checkboxsg.length === checkboxsg.filter(":checked").length;
    if (allg) {
      $("#" + checkbox_classes).prop("checked", true);
    }
    checkAll();
  });

  $(".groupAll").change(function () {
    var group_id = $(this).attr("id");
    $("." + group_id).prop("checked", $(this).prop("checked"));
    checkAll();
  });

  function checkAll() {
    var checkboxs = $(".checkbox-permission");
    var all = checkboxs.length === checkboxs.filter(":checked").length;
    if (all) {
      $("#checkAll").prop("checked", true);
      $(".groupAll").prop("checked", true);
    }
  }
});

window.show_hide_allieds = function(){
  if($("#user_user_type_id").val() == "3"){
    $("#div_allied_id").show();
    $("#div_seat_id").hide();
  }else{
    $("#div_allied_id").hide();
    $("#div_seat_id").show();
  }
}
