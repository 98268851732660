$( document ).ready(function() {
  var tax_id
  var options

  $("#cost").hide()
  $("#product_warehouse").hide()
  inventoryable()

  $('#inventoryable').on('change', function() {
    inventoryable()
  });

  function inventoryable(){
    if($('#inventoryable:checked').val() == true ){
      $("#cost").show()
      $("#product_warehouse").show()
    }else{
      $("#cost").hide()
      $("#product_warehouse").hide()
    }
  }

  // $(document).delegate('.value-with-iva','change',function(){
  //   field_id = this.id.split('_')[3]
  //   var value_with_iva = $('#product_prices_attributes_' + field_id +  '_value_with_iva').val()
  //   var value_before_iva = value_with_iva / 1.19
  //   $('#product_prices_attributes_' + field_id +  '_value_before_iva').val(Math.round(value_before_iva));
  // });

  $(document).delegate('.value-before-iva','change',function(){
    field_id = this.id.split('_')[3]
    tax_id = $('#product_tax').val();
    if (tax_id != null && tax_id != undefined && tax_id != '') {
      $.ajax({
        url: '/get_info_tax',
        type: 'get',
        data: {
          tax_id: tax_id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          if (data.tax != null && data.tax.length != 0) {
            var value_before_iva = parseFloat($('#product_prices_attributes_' + field_id +  '_value_before_iva').val().replace(/[^\d\.\-]/g, ""));
            var value_with_iva = value_before_iva * (1+(data.tax.porcentage/100))
            $('#product_prices_attributes_' + field_id +  '_value_with_iva').val(Math.round(value_with_iva));
          }
        },
      });
    }
    $('.currency-input-mask').each(function(i, el) {
      new Cleave(el, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      });
    });
  });

  $(document).delegate('#product_tax','change',function(){
    tax_id = $(this).val();
    if (tax_id != null && tax_id != undefined && tax_id != '') {
      $.ajax({
        url: '/get_info_tax',
        type: 'get',
        data: {
          tax_id: tax_id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          if (data.tax != null && data.tax.length != 0) {
            $( '.value-before-iva' ).each(function( ) {
              field_id = this.id.split('_')[3]
              var value_before_iva = parseFloat($('#product_prices_attributes_' + field_id +  '_value_before_iva').val().replace(/[^\d\.\-]/g, ""));
              var value_with_iva = value_before_iva * (1+(data.tax.porcentage/100))
              $('#product_prices_attributes_' + field_id +  '_value_with_iva').val(Math.round(value_with_iva));
            });
          }
        },
      });
    }
    $('.currency-input-mask').each(function(i, el) {
      new Cleave(el, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      });
    });
  });

  $(document).delegate('.warehouse_remove','click', function(){
    $('#add_warehouse_to_product').nextAll().remove();
    $('#add_warehouse_to_product').prop('disabled', false);
  });

  if (window.location.href.indexOf("edit") > -1){
    if ($('.product_input').length == (($('option', '.product_input').length)/$('.product_input').length)){
      $('#add_warehouse_to_product').after('<p class="text-error">No hay más bodegas disponibles</p>');
      $('#add_warehouse_to_product').prop('disabled', true);
    }
  }

  $(document).delegate('.inventory_fields','click',function(){
    setTimeout(function(){
      warehouse_on_details =  $('.product_input').length;
      warehouse_on_select = (($('option', '.product_input').length)/warehouse_on_details)-1;
      if ( warehouse_on_details > 1 && warehouse_on_details < warehouse_on_select ){
        option = []
        $('.product_input').each(function(i, el){
          index = this.id.split('_')[3];
          option[i] = $(this).val();
          $(this).prop('id',`product_inventories_attributes_${i}_warehouse_id`);
          $(`#product_inventories_attributes_${index}_quantity_minimum`).prop('id',`product_inventories_attributes_${i}_quantity_minimum`);
          $(`#product_inventories_attributes_${index}_quantity_initial`).prop('id',`product_inventories_attributes_${i}_quantity_initial`);
          $(`#product_inventories_attributes_${index}__destroy`).prop('id',`product_inventories_attributes_${i}__destroy`);
          if (i == 0){
            options = $('option', this).sort().clone();
          } else {
            for (var j = 0; j < option.length-1; j++) {
              $(`#product_inventories_attributes_${i}_warehouse_id`).empty();
              $(`#product_inventories_attributes_${i}_warehouse_id`).append(options);
              $(`#product_inventories_attributes_${i}_warehouse_id option[value="${option[j]}"]`).detach();
            };
          }
        })
      }
      if (warehouse_on_details >= warehouse_on_select){
        $('#add_warehouse_to_product').after('<p class="text-error">No hay mas bodegas disponibles</p>');
        $('#add_warehouse_to_product').prop('disabled', true);
      }
    }, 500);
  })


});
