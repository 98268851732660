$( document ).ready(function() {
  var index;
  var subindex;
  var paid_amount;
  var retention_option_aux = "";
  var detail_total = {};
  var for_retention_tax = {};
  var for_retention_discount = {};

  const formatToCurrency = amount => {
    return "$" + amount.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  if (window.location.href.indexOf("edit") > -1){
    var retention_array = {}
    var retention_option = ""
    $.ajax({
      url: '/get_info_retention_bill',
      type: 'get',
      data: {},
      dataType: 'json',
      async: false,
      success: function(data) {
        if (data.detail != null) {
          if (data.detail.length != 0 ) {
            $.each(data.detail,function(index,value){
              retention_array[index] = `<option value="`+value.id+`">${value.name}</option>`
              retention_option += retention_array[index].toString();
            })
          }
        }
      }
    });
    retention_option_aux = retention_option;
    setTimeout(on_edit, 1000);
  }

  function is_partial_payment(id){
    paid_amount = 0;
    $.ajax({
      url: '/get_info_partial_bill',
      type: 'get',
      data: {
        detail_id: id,
      },
      dataType: 'json',
      async: false,
      success: function(data) {
        if (data.detail != null) {
          if (data.detail.length != 0 ) {
            $.each(data.detail,function(index,value){
              paid_amount += parseFloat(value.value);
            })
          }
        }
      }
    });
  };

  $(document).delegate('#bill_customer','change',function(){
    var retention_array = {}
    var retention_option = ""
    var subtotal = 0
    var customer_id = $('#bill_customer').val();
    if (customer_id != null && customer_id != undefined && customer_id != '') {
      $.ajax({
        url: '/get_info_customer_bill',
        type: 'get',
        data: {
          customer_id: customer_id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          if (data.customer != null) {
            if (data.customer.length != 0 ) {
              $('#bill-name').val(data.name);
              $('#bill-document').val(data.customer.document);
              $('#bill-cellphone').val(data.customer.cellphone);
              $('#bill-customer-portfolio').text("CARTERA: " + formatToCurrency(parseInt(data.customer.portfolio)))
            }
          }
        }
      })
    }else{
      $('#bill-customer-portfolio').text("")
    }
    $.ajax({
      url: '/get_info_retention_bill',
      type: 'get',
      data: {},
      dataType: 'json',
      async: false,
      success: function(data) {
        if (data.detail != null) {
          if (data.detail.length != 0 ) {
            $.each(data.detail,function(index,value){
              retention_array[index] = `<option value="`+value.id+`">${value.name}</option>`
              retention_option += retention_array[index].toString();
            })
          }
        }
      }
    });
    retention_option_aux = retention_option;
    $('.payment_details').empty();
    $(".payment_retention_details").empty();
    detail_id = $('#bill_customer').val();
    if (detail_id != null && detail_id != undefined && detail_id != '') {
      $.ajax({
        url: '/get_info_customer_invoice',
        type: 'get',
        data: {
          detail_id: detail_id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          if (data.detail != null) {
            if (data.detail.length != 0 ) {
                $.each(data.detail,function(index,value){
                var total_retention_value = 0;
                detail = `<tr class="nested-fields">
                    <td class="d-none" >
                      <input class="form-control" type="text" name="bill[payment_details_attributes][${index}][supplier_invoice_id]" id="bill_payment_details_attributes_${index}_supplier_invoice_id">
                    </td>
                    <td style="max-width: 150px;">
                      <input class="form-control" type="text" name="bill[payment_details_attributes][${index}][consecutive]" id="bill_payment_details_attributes_${index}_consecutive" readonly="readonly">
                    </td>
                    <td class="text-wrap" style="max-width: 150px;">
                      <input class="form-control bills_total_detail currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][total]" id="bill_payment_details_attributes_${index}_total" >
                    </td>
                    <td class="d-none">
                      <input class="form-control" type="text" name="bill[payment_details_attributes][${index}][paid_value]" id="bill_payment_details_attributes_${index}_paid_value">
                    </td>
                    <td style="max-width: 150px;">
                      <input class="form-control bill_adjustment currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][adjustment]" id="bill_payment_details_attributes_${index}_adjustment" >
                    </td>
                    <td class="d-none">
                      <input class="form-control" typ e="text" name="bill[payment_details_attributes][${index}][base]" id="bill_payment_details_attributes_${index}_base" readonly="readonly">
                    </td>
                    <td class="text-wrap" colspan="3" style="max-width: 600px;">
                      <table class="table">
                        <tbody class="payment_retention_details" id="payment_retention_details_${index}">
                        </tbody>
                      </table>
                      <div>
                        <button type="button" id="bill_retention_button_${index}" class="btn btn-outline-primary btn-round waves-effect waves-light m-1 float-right bill_retention_button">Agregar Retención</button>
                      </div>
                    </td>
                    <td class="text-wrap" style="max-width: 150px;">
                      <input class="form-control received_value currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][value]" id="bill_payment_details_attributes_${index}_value" readonly="readonly">
                    </td>
                    <td class="d-none">
                      <input class="form-control detail_retention_value" type="text" name="bill[payment_details_attributes][${index}][retention_value]" id="bill_payment_details_attributes_${index}_retention_value">
                    </td>
                    <td>
                      <input value="false" type="hidden" name="bill[payment_details_attributes][${index}][_destroy]" id="bill_payment_details_attributes_${index}__destroy"><a class="btn btn-danger btn-sm btn-round waves-effect waves-light m-1 detail_remove remove_fields dynamic" title="Remover Detalle" href="#">
                        <i class="fa fa-trash-o" title="Remover"></i>
                  </a>  </td>
                  </tr>`
                  is_partial_payment(value.id);
                  $(`.payment_details`).append(detail);
                  for_retention_tax[index] = parseFloat(value.tax);
                  for_retention_discount[index] = parseFloat(value.discount);
                  detail_total[index] = parseFloat(value.total-paid_amount);
                  $(`#bill_payment_details_attributes_${index}_supplier_invoice_id`).val(value.id);
                  $(`#bill_payment_details_attributes_${index}_consecutive`).val(value.numeration);
                  $(`#bill_payment_details_attributes_${index}_total`).val(detail_total[index]);
                  $(`#bill_payment_details_attributes_${index}_paid_value`).val(value.total);
                  $(`#bill_payment_details_attributes_${index}_base`).val(detail_total[index]-for_retention_tax[index]+for_retention_discount[index]);
                  $(`#bill_payment_details_attributes_${index}_payment_id`).val(value.id);
                  $(`#bill_payment_details_attributes_${index}_adjustment`).val(0);
                  subtotal += Math.round(value.subtotal);
                  if (value.customer_invoice_retentions.length){
                  $.each(value.customer_invoice_retentions, function(subindex,subvalue){
                    var retention_value = 0;
                    var percentage = 0;
                    retention = `<tr class="nested-fields" style="border-bottom: 1px solid #DEE2E6 !important;">
                      <td class="text-wrap"  style="max-width: 200px;">
                        <select class="form-control payment_retention" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_id]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_id"><option value="">SELECCIONAR</option>
                        ${retention_option}
                        </select>
                      </td>
                      <td class="text-wrap" style="max-width: 200px;">
                        <input class="form-control payment_retention_base currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][base]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_base" readonly="readonly">
                      </td>
                      <td class="d-none" >
                        <input class="form-control" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_percentage]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_percentage">
                      </td>
                      <td class="text-wrap"  style="max-width: 200px;">
                        <input class="form-control retention_value currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_value]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_value" readonly="readonly">
                      </td>
                      <td width = '50rem'>
                        <input value="false" type="hidden" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][_destroy]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}__destroy"><a class="retention_remove text-danger remove_fields dynamic" title="Remover Retención" href="#">
                          x
                        </a>
                      </td>b
                    </tr>`;
                    percentage = ((subvalue.percentage)/100);
                    retention_value = percentage*(detail_total[index]-for_retention_tax[index]+for_retention_discount[index]);
                    total_retention_value += retention_value;
                    $(`#payment_retention_details_${index}`).append(retention);
                    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_id`).val(subvalue.retention_id);
                    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_base`).val(Math.round(detail_total[index]-for_retention_tax[index]+for_retention_discount[index]));
                    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_value`).val(Math.round(retention_value));
                    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_percentage`).val(percentage);
                    });
                  }
                $(`#bill_payment_details_attributes_${index}_value`).val(Math.round(detail_total[index]-total_retention_value));
                $(`#bill_payment_details_attributes_${index}_retention_value`).val(Math.round(total_retention_value));
              })
            }
          }
        },
      });
    }
    $('#bill_subtotal').val(Math.round(subtotal));
    final_calc();
  });

  $(document).delegate('.payment_retention', 'change', function(){
    index = this.id.split('_')[4];
    subindex = this.id.split('_')[9];
    var detail_id = $('#' + this.id).val();
    if (detail_id != null && detail_id != undefined && detail_id != '') {
      $.ajax({
        url: '/get_info_retention_bill',
        type: 'get',
        data: {
          detail_id: detail_id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          if (data.detail != null) {
            if (data.detail.length != 0 ) {
              $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_percentage`).val(data.detail.porcentage/100);
              $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_base`).val($(`#bill_payment_details_attributes_${index}_base`).val());
            }
          }
        }
      })
    }
    setTimeout(retention_value_calc, 1000);
})

$(document).delegate('.bill_retention_button','click',function(){
  var closest = $(this).closest('.nested-fields').find('.received_value').attr('id');
  index = closest.split('_')[4];
  var subindex = $(this).closest('.nested-fields').find('.payment_retention_base').length;
  retention = `<tr class="nested-fields" style="border-bottom: 1px solid #DEE2E6 !important;">
      <td class="text-wrap"  style="max-width: 200px;">
        <select class="form-control payment_retention" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_id]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_id"><option value="">SELECCIONAR</option>
        ${retention_option_aux}
        </select>
      </td>
      <td class="text-wrap"  style="max-width: 200px;">
        <input class="form-control payment_retention_base currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][base]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_base" readonly="readonly">
      </td>
      <td class="d-none" >
        <input class="form-control" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_percentage]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_percentage">
      </td>
      <td class="text-wrap"  style="max-width: 200px;">
        <input class="form-control retention_value currency-input-mask" type="text" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][retention_value]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_value" readonly="readonly">
      </td>
      <td>
        <input value="false" type="hidden" name="bill[payment_details_attributes][${index}][payment_retention_details_attributes][${subindex}][_destroy]" id="bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}__destroy"><a class="retention_remove text-danger remove_fields dynamic" title="Remover Retención" href="#">
          x
        </a>
      </td>
    </tr>`;
  $(`#payment_retention_details_${index}`).append(retention);
  $('.currency-input-mask').each(function(i, el) {
    new Cleave(el, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    });
  });
});

  $(document).delegate('.detail_remove','click',function(){
    setTimeout(final_calc, 1000);
  });

  $(document).delegate('.payment_retention_base','change',function(){
    index = this.id.split('_')[4];
    subindex = this.id.split('_')[9];
    setTimeout(retention_value_calc, 1000);
  });


  $(document).delegate('.retention_remove','click', function(){
    var closest = $(this).closest('tbody').find('.retention_value').attr('id');
    index = closest.split('_')[4];
    setTimeout(shared_calc, 500);
  })

  function on_edit(){
    $('.payment_retention_details').each(function(index, value){
      $(this).attr('id', `payment_retention_details_${index}`);
    });
  }

  $(document).delegate('.bills_total_detail','change',function(){
    number = parseFloat($(this).val().replace(/[^\d\.\-]/g, ""))
    index = this.id.split('_')[4];
    if (!$.isNumeric(number) ||  number<0 || number>detail_total[index] ){
      $(this).nextAll().remove();
      $(this).after('<p class="text-error">Valor no valido</p>');
      $('#order-save').prop('disabled', true);
    } else {
        $(this).nextAll().remove();
        $('#order-save').prop('disabled', false);
        var new_base = 0;
        var leads;
        new_base = parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
        $(`#bill_payment_details_attributes_${index}_base`).val(new_base-for_retention_tax[index]+for_retention_discount[index]);
        $(`#bill_payment_details_attributes_${index}_value`).val(new_base);
        shared_calc();
        leads = $(`#payment_retention_details_${index}`);
        $(leads).find('.payment_retention_base').each(function(){
          index = this.id.split('_')[4];
          subindex = this.id.split('_')[9];
          retention_value_calc();
        })
    }
  });

  function retention_value_calc(){
    var retention_percentage = 0;
    var base = 0;
    var retention_value = 0;
    retention_percentage = parseFloat($(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_percentage`).val());
    base = parseFloat($(`#bill_payment_details_attributes_${index}_base`).val().replace(/[^\d\.\-]/g, ""));
    retention_value = retention_percentage*base;
    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_retention_value`).val(Math.round(retention_value));
    $(`#bill_payment_details_attributes_${index}_payment_retention_details_attributes_${subindex}_base`).val(base);
    shared_calc();
  }

  function shared_calc(){
    var total = 0
    var total_retention = 0;
    var leads;
    var adjustment = 0;
    total = parseFloat($(`#bill_payment_details_attributes_${index}_total`).val().replace(/[^\d\.\-]/g, ""));
    leads = $(`#payment_retention_details_${index}`);
    adjustment = parseFloat($(`#bill_payment_details_attributes_${index}_adjustment`).val().replace(/[^\d\.\-]/g, ""));
    $(leads).find('.retention_value').each(function(){
      total_retention += parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
    })
    $(`#bill_payment_details_attributes_${index}_value`).val(total - total_retention + adjustment);
    $(`#bill_payment_details_attributes_${index}_retention_value`).val(total_retention);
    final_calc();
  }

  function final_calc(){
    var total_retention = 0
    var master_subtotal = 0
    var master_total = 0

    $('.received_value').each(function(){
      master_total += parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
    })
    $('.retention_value').each(function(){
      total_retention += parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
    })
    $('.bills_total_detail').each(function(){
      master_subtotal += parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
    })
    $('#bill_subtotal').val(master_subtotal);
    $('#bill_retention').val(Math.round(total_retention));
    $('#bill_total').val(Math.round(master_total));

    $('.currency-input-mask').each(function(i, el) {
      new Cleave(el, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      });
    });

  }

  $(document).delegate('.bill_adjustment','change',function(){
    index = this.id.split('_')[4];
    if (!$.isNumeric(parseFloat($(this).val().replace(/[^\d\.\-]/g, "")))){
      $(this).nextAll().remove();
      $(this).after('<p class="text-error">Debe ser numérico</p>');
      $('#order-save').prop('disabled', true);
    } else {
      $(this).nextAll().remove();
      $('#order-save').prop('disabled', false);
      retention_value_calc();
    }
  });

});
