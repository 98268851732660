$(document).ready(function () {
  var select_id
  var product_id
  var customer
  var tax_null
  var tax_null_percentage
  var products = []


  const formatToCurrency = amount => {
    amount = Math.round(amount)
    return amount.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  $(".order_product").select2({
    placeholder: 'Selecciona un producto',
    dropdownPosition: 'below',
  });

  $(document).delegate('#add-detail-product-order', 'click', function () {
    $.ajax({
      url: '/get_product_by_warehouse_order',
      type: 'get',
      dataType: 'json',
      async: false,
      success: function (data) {
        if (data.detail != null) {
          if (data.detail.length != 0) {
            products = [];
            products[0] = { id: 0, text: "Selecciona un producto" }
            $.each(data.detail, function (i, value) {
              products[i + 1] = { id: value.id, text: value.name }
            })
            $(".order_product").each(function(index, value){
              if ( $(this).val() == 0 ) {
                $(this).html('').select2({data: products})
              }
            });
          }
        }
      }
    });
  })

  $('#order_country').change(function () {
    $('#order_department').html("");
    var country_id = $(this).val();
    if (country_id) {
      $.ajax({
        url: "/get_departments_by_country",
        type: "get",
        data: {
          country_id: country_id,
        },
        dataType: "json",
        async: false,
        success: function (data) {
          if (data.departments.length != 0) {
            var html = ""
            html += "<option disabled selected>SELECCIONAR</option>"
            for (var i = 0; i < data.departments.length; i++) {
              html += "<option value='" + data.departments[i]["id"] + "'>" + data.departments[i]["name"] + " / " + data.departments[i]["cod_department"] + "</option>"
            }
            $("#order_department").append(html)
          }
        },
      });
    }
  });

  $(document).delegate('#order_warehouse_id', 'change', function () {
    var id = $(this).val();
    $('.order_product').html("");
    $.ajax({
      url: '/get_product_by_warehouse_order',
      type: 'get',
      data: {
        detail_id: id,
      },
      dataType: 'json',
      async: false,
      success: function (data) {
        if (data.detail != null) {
          if (data.detail.length != 0) {
            products = [];
            products[0] = { id: 0, text: "Selecciona un producto" }
            $.each(data.detail, function (i, value) {
              products[i + 1] = { id: value.id, text:  value.name }
            })
            $(".order_product").html('').select2({ data: products })
          }
        }
      }
    });
  });

  $(document).delegate('.order_product', 'focus', function () {
    var id = $('#order_warehouse_id').val();
    index = this.id.split('_')[4];
    $(this).html("");
    $.ajax({
      url: '/get_product_by_warehouse',
      type: 'get',
      data: {
        detail_id: id,
      },
      dataType: 'json',
      async: false,
      success: function (data) {
        if (data.detail != null) {
          if (data.detail.length != 0) {
            var html = "";
            html += "<option disabled selected>SELECCIONAR</option>";
            $.each(data.detail, function (i, value) {
              html += "<option value='" + value.id + "'>" + value.name + "</option>"
            })
            $(`#order_order_details_attributes_` + index + `_product_id`).append(html);
          }
        }
      }
    });
  })

  $("#order_department").change(function () {
    $("#order_city").html("");
    var department_id = $(this).val();
    if (department_id) {
      $.ajax({
        url: "/get_cities_by_department",
        type: "get",
        data: {
          department_id: department_id,
        },
        dataType: "json",
        async: false,
        success: function (data) {
          if (data.cities.length != 0) {
            var html = ""
            html += "<option disabled selected>SELECCIONAR</option>"
            for (var i = 0; i < data.cities.length; i++) {
              html += "<option value='" + data.cities[i]["id"] + "'>" + data.cities[i]["name"] + " / " + data.cities[i]["cod_city"] + "</option>"
            }
            $("#order_city").append(html)
          }
        },
      });
    }
  });

  $('#order_customer').change(function () {

    var customer_id = $('#order_customer').val();
    if (customer_id != null && customer_id != undefined && customer_id != '') {
      $.ajax({
        url: '/get_info_customer',
        type: 'get',
        data: {
          customer_id: customer_id,
        },
        dataType: 'json',
        async: false,
        success: function (data) {
          customer = data.customer
          if (data.adviser != null) {
            if (data.adviser.length != 0) {
              $('#order_adviser').val(data.adviser[0].id);
            }
          } else {
            $('#order_adviser').val('');
          }
          if (data.price_list != null) {
            if (data.price_list.length != 0) {
              $('#order_price_list').val(data.price_list[0].id);
            }
          } else {
            $('#order_price_list').val('');
          }
          if (data.payment_time != null) {
            if (data.payment_time.length != 0) {
              $('#order_payment_time').val(data.payment_time[0].id);
            }
          } else {
            $('#order_payment_time').val('');
          }
          if (data.customer.document_type == 'NIT' || data.customer.document_type == 'NIT DE OTRO PAÍS') {
            $('#order_customer_name').val(data.customer.name);
          } else {
            $('#order_customer_name').val(data.customer.first_name + ' ' + data.customer.last_names);
          }
          $('#order_customer_document_type').val(data.customer.document_type);
          $('#order_customer_document').val(data.customer.document);
          $('#order_customer_phone').val(data.customer.cellphone);
          $('#order_customer_address').val(data.customer.address);
          $('#order_country').val(data.customer.country_id);
          $('#order_department').val(data.customer.department_id);
          $('#order_city').val(data.customer.city_id);
          if (data.customer.has_tax == false) {
            // $('.order_tax').val("");
            $.ajax({
              url: '/get_null_tax',
              type: 'get',
              dataType: 'json',
              async: false,
              success: function (data) {
                tax_null = data.tax.id
                tax_null_percentage = data.tax.percentage
                if (data.tax != null) {
                  if (data.tax.length != 0) {
                    $('.order_tax').val(data.tax.id);
                    $('.product_percentage').val(0);
                    $('.product_tax').val(0);
                  }
                }
              },
            });
          }
          // $('.order_tax').val(data.customer.tax_id);
        },
      });
    }
    $('.order-product-price').each(function () {
      select_id = this.id.split('_')[4]
      product_id = $('#order_order_details_attributes_' + select_id + '_product_id').val();
      //get_price()
    });
    calc()
  });

  $(document).delegate('.order_product', 'change', function () {
    var customer_id = $('#order_customer').val();
    if (customer_id != null && customer_id != undefined && customer_id != '') {
      $.ajax({
        url: '/get_info_customer',
        type: 'get',
        data: {
          customer_id: customer_id,
        },
        dataType: 'json',
        async: false,
        success: function (data) {
          customer = data.customer
        },
      });
    }
    select_id = this.id.split('_')[4]
    product_id = $('#' + this.id).val();
    if (product_id != null && product_id != undefined && product_id != '') {
      $.ajax({
        url: '/get_info_product_order',
        type: 'get',
        data: {
          product_id: product_id,
        },
        dataType: 'json',
        async: false,
        success: function (data) {
          if (data.product != null) {
            if (data.product.length != 0) {
              $('#order_order_details_attributes_' + select_id + '_product_name').val(data.product.name);
              $('#order_order_details_attributes_' + select_id + '_reference').val(data.product.reference);
              $('#order_order_details_attributes_' + select_id + '_description').val(data.product.description);
              $('#order_order_details_attributes_' + select_id + '_price').val(data.product.price);
              $('#order_order_details_attributes_' + select_id + '_discount').val(0);
              $('#order_order_details_attributes_' + select_id + '_quantity').val(1);
              $('#order_order_details_attributes_' + select_id + '_product_consecutive').val(0);
              if (customer.has_tax == true) {
                $('#order_order_details_attributes_' + select_id + '_tax_id').val(data.product.tax_id);
                $('#order_order_details_attributes_' + select_id + '_tax_name').val(data.product.tax.name + " (" + data.product.tax.porcentage + "%)");
                $('#order_order_details_attributes_' + select_id + '_tax_percentage').val(data.product.tax.porcentage);
              } else {
                  $.ajax({
                    url: '/get_null_tax',
                    type: 'get',
                    dataType: 'json',
                    async: false,
                    success: function (data) {
                      tax_null = data.tax.id
                      tax_null_percentage = data.tax.percentage
                      if (data.tax != null) {
                        if (data.tax.length != 0) {
                          $('#order_order_details_attributes_' + select_id + '_tax_id').val(data.tax.id);
                          $('#order_order_details_attributes_' + select_id + '_tax_name').val(data.tax.name + " (" + data.tax.porcentage + "%)");
                          $('#order_order_details_attributes_' + select_id + '_tax_percentage').val(data.tax.porcentage);
                        }
                      }
                    },
                  });
              }

            }
          }
        },
      });
      //get_price()
      calc()
    }

    $(document).delegate('#order_order_details_attributes_' + select_id + '_quantity', 'change', function () {
      calc()
    });

    $(document).delegate('#order_order_details_attributes_' + select_id + '_discount', 'change', function () {
      calc()
    });

    $(document).delegate('#order_order_details_attributes_' + select_id + '_price', 'change', function () {
      select_id = this.id.split('_')[4]
      calc()
    });

  });

  /*function get_price() {
    var price_list = $('#order_price_list').val()
    $.ajax({
      url: '/get_info_price_order',
      type: 'get',
      data: {
        product_id: product_id,
        price_list: price_list,
      },
      dataType: 'json',
      async: false,
      success: function (data) {
        if (data.price != null) {
          if (data.price.length != 0) {
            $('#order_order_details_attributes_' + select_id + '_price').val((data.price.value_before_iva));
          }
        } else {
          $('#order_order_details_attributes_' + select_id + '_price').val(0);
        }
      },
    });
  }*/

  $(document).delegate('#order_price_list', 'change', function () {
    $('.order-product-price').each(function () {
      select_id = this.id.split('_')[4]
      product_id = $('#order_order_details_attributes_' + select_id + '_product_id').val();
      //get_price()
      calc()
    });
  });

  $(document).delegate('.order_tax', 'change', function () {
    select_id = this.id.split('_')[4]
    var tax_id = $('#' + this.id).val();
    if (tax_id != null && tax_id != undefined && tax_id != '') {
      $.ajax({
        url: '/get_info_tax',
        type: 'get',
        data: {
          tax_id: tax_id,
        },
        dataType: 'json',
        async: false,
        success: function (data) {
          if (data.tax != null) {
            if (data.tax.length != 0) {
              $('#order_order_details_attributes_' + select_id + '_tax_name').val(data.tax.name);
              $('#order_order_details_attributes_' + select_id + '_tax_percentage').val(data.tax.porcentage);
            }
          }
        },
      });
    }
    calc2()
  });

  $(document).delegate('.order_product_discount', 'change', function () {
    if (!$.isNumeric($(this).val()) || $(this).val() < 0) {
      $(this).nextAll().remove();
      $(this).after('<p class="text-error">Debe ser mayor o igual a 0</p>');
      $('#order-save').prop('disabled', true);
    } else {
      $(this).nextAll().remove();
      $('#order-save').prop('disabled', false);
      select_id = this.id.split('_')[4]
      calc()
    }
  });

  $(document).delegate('.product_quantity_order', 'change', function () {
    if (!$.isNumeric($(this).val()) || $(this).val() < 1) {
      $(this).nextAll().remove();
      $(this).after('<p class="text-error">Debe ser mayor 0</p>');
      $('#order-save').prop('disabled', true);
    } else {
      $(this).nextAll().remove();
      $('#order-save').prop('disabled', false);
      select_id = this.id.split('_')[4]
      calc()
    }
  });

  $(document).delegate('.order_tax', 'change', function () {
    select_id = this.id.split('_')[4]
    calc()
  });

  $(document).delegate('#order-payment-method', 'change', function () {
    if ($('#order-payment-method').val() == 'CONTADO') {
      $.ajax({
        url: '/in_cash_payment_time',
        type: 'get',
        data: {
        },
        dataType: 'json',
        async: false,
        success: function (data) {
          if (data.payment_time != null) {
            $('#order_payment_time').val(data.payment_time.id);
          }
        },
      });
    } else {
      $('#order_payment_time').val(customer.payment_time_id);
    }
  });

  $(document).delegate('.product_order_remove', 'click', function () {
    setTimeout(calc2, 1000);
  });

  function calc() {
    var quantity = $('#order_order_details_attributes_' + select_id + '_quantity').val()
    var discount = $('#order_order_details_attributes_' + select_id + '_discount').val()
    var price = parseFloat($('#order_order_details_attributes_' + select_id + '_price').val().replace(/[^\d\.\-]/g, ""));
    // var price = parseFloat($('#order_order_details_attributes_' + select_id +  '_price').val().replace(/[^\d\.\-]/g, ""));
    var discount_value = (price * (discount / 100)) * quantity
    var total = (price - (price * (discount / 100))) * quantity
    var tax_percentage = $('#order_order_details_attributes_' + select_id + '_tax_percentage').val()
    // var tax_value = (tax_percentage/100) * price * quantity
    var tax_value = (tax_percentage / 100) * total;
    $('#order_order_details_attributes_' + select_id + '_subtotal').val(discount_value);
    $('#order_order_details_attributes_' + select_id + '_total').val(Math.round(total));
    $('#order_order_details_attributes_' + select_id + '_tax_value').val(tax_value);
    calc2();
  }

  function calc2() {
    var total_tax = 0
    var total_sale = 0
    var order_discount = 0
    var order_weight = 0

    $('.product_tax').each(function () {
      total_tax += parseFloat($(this).val());
    });
    $('.product_total').each(function () {
      total_sale += parseFloat($(this).val().replace(/[^\d\.\-]/g, ""));
    });
    $('.product_subtotal').each(function () {
      order_discount += parseFloat($(this).val());
    });
    $('.product_weight_order').each(function () {
      select_id = this.id.split('_')[4]
      quant = $('#order_order_details_attributes_' + select_id + '_quantity').val();
      order_weight += parseFloat($(this).val() * quant);
    });

    $('#order_total_weight').val(order_weight);
    $('#order_total_sale').val(parseFloat(total_sale + order_discount));
    $('#order_discount').val(order_discount);
    $('#order_subtotal').val(total_sale);
    $('#order_tax').val(total_tax);
    $('#order_total').val(parseFloat(total_sale + total_tax));

    $('.currency-input-mask').each(function (i, el) {
      new Cleave(el, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      });
    });
  }

});
