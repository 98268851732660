$( document ).ready(function() {
  $("#document_input").keypress(function (e){
    if($("#document_type").val() == "NIT"){
      if( e.which!=8 && e.which!=0 && (e.which<48 || e.which>57)){
        $(".document_error").text('Debe ser un número');
      }else{
        $(".document_error").text('');
      }
    }
  });

  // $("#customer-credit").val(0);
  $("#customer-portfolio").val(0);
  if ($("#is-credit").is(":checked")){
    $("#credit").show()
  }else{
    $("#credit").hide()
  }

  if($("#document_type").val() == "NIT" || $("#document_type").val() == "NIT DE OTRO PAÍS"){
    $("#name").show()
    $("#first_name").hide()
    $("#second_name").hide()
    $(".last_names").hide()
  }else{
    $("#name").hide()
    $("#first_name").show()
    $("#second_name").show()
    $(".last_names").show()
  }

  if($("#document_type").val() == "NIT"){
    $("#regime_type").show()
    $(".document_dv").show()
    $(".document").addClass( "col-sm-8" );
  }else{
    $("#regime_type").hide()
    $(".document_dv").hide()
    $(".document").removeClass( "col-sm-8" ).addClass( "col" );
  }

  $(document).delegate("#is-credit", "change", function(){
    if ($(this).is(":checked")){
      $("#credit").show()
    }else{
      $("#credit").hide()
    }
  });

  $("#document_type").on("change", function() {
    if(this.value == "NIT" || this.value == "NIT DE OTRO PAÍS"){
      $("#name").show()
      $("#regime_type").show()
      $("#first_name").hide()
      $("#second_name").hide()
      $(".last_names").hide()
      // $(".document_dv").addClass( "col-sm-3" );
    }else{
      $("#name").hide()
      $("#regime_type").hide()
      $("#first_name").show()
      $("#second_name").show()
      $(".last_names").show()
      // $(".document_dv").removeClass( "col-sm-3" );
    }
    if(this.value == "NIT"){
      $("#regime_type").show()
      $(".document_dv").show()
      $(".document").addClass( "col-sm-8" );
    }else{
      $("#regime_type").hide()
      $(".document_dv").hide()
      $(".document").removeClass( "col-sm-8" ).addClass( "col" );
    }
  });

  $("#document_input").on("change", function() {
    nit = this.value
    multiplicadores = [3,7,13,17,19,23,29,37,41,43,47,53,59,67,71]
    nit_length = nit.length
    acumulador = 0
    for (var i = 0; i <= (nit_length - 1); i++) {
      temp = nit[parseInt((nit_length - 1)) - i]
      acumulador = acumulador + (parseInt(temp) * multiplicadores[i])
    }
    residuo = acumulador % 11
    if (residuo > 1) {
      residuo = 11 - residuo
    }else{
      residuo
    }
    $("#dv").val(residuo.toString());
  });

  $("#customer_country").change(function(){
    $("#customer_department").html("");
    var country_id = $("#customer_country").val();
    if (country_id != null && country_id != undefined && country_id != "") {
      $.ajax({
        url: "/get_departments_by_country",
        type: "get",
        data: {
          country_id: country_id,
        },
        dataType: "json",
        async: false,
        success: function(data) {
          if (data.departments.length != 0) {
            var html = ""
            html += "<option disabled selected>SELECCIONAR</option>"
            for (var i = 0; i < data.departments.length; i++) {
              html += "<option value='"+data.departments[i]["id"]+"'>"+data.departments[i]["name"]+"</option>"
            }
            $("#customer_department").append(html)
          }
        },
      });
    }
  });

  $("#customer_department").change(function(){
    $("#customer_city").html("");
    var department_id = $("#customer_department").val();
    if (department_id != null && department_id != undefined && department_id != "") {
      $.ajax({
        url: "/get_cities_by_department",
        type: "get",
        data: {
          department_id: department_id,
        },
        dataType: "json",
        async: false,
        success: function(data) {
          if (data.cities.length != 0) {
            var html = ""
            html += "<option disabled selected>SELECCIONAR</option>"
            for (var i = 0; i < data.cities.length; i++) {
              html += "<option value='"+data.cities[i]["id"]+"'>"+data.cities[i]["name"]+"</option>"
            }
            $("#customer_city").append(html)
          }
        },
      });
    }
  });

});
