$( document ).ready(function() {

    $(document).delegate('#headquarterdetail_id', 'change', function(){
        var id = $(this).val();
        $.ajax({
        url: '/get_headquarter_data',
        type: 'get',
        data: {
            id: id,
        },
        dataType: 'json',
        async: false,
        success: function(data) {
            if (data.detail.length != 0 ) {
                $.each(data.detail,function(i,value){
                    $('.resolution').val(value.bill_resolution)
                    $('.start_consecutive').val(value.start_consecutive)
                    $('.end_consecutive').val(value.end_consecutive)
                    $('.expiration_date').val(value.expiration_date)
                })
            }
        }
        });
    });
});
