$( document ).ready(function() {
  // $("#value").hide()
  if( $('#value_type').val() == "PORCENTAJE"){
    $("#value").show()
  }else{
    $("#value").hide()
  }
  $('#value_type').on('change', function() {
    if(this.value == "PORCENTAJE"){
      $("#value").show()
    }else{
      $("#price_list_value").val("")
      $("#value").hide()
    }
  });
});
